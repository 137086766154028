.container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.button {
  width: 128px;
  height: 88px;
  align-self: center;
}
.textinput {
  width: 50%;
  height: 72px;
  align-self: center;
}
.textinput1 {
  width: 100%;
  height: 106px;
  align-self: center;
}
.textarea {
  height: 183px;
  align-self: center;
}